import axios from 'axios'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const getCourseReporting = async (body: { course_id: string; asTeacher: boolean }) => {
	return await handleApi(
		axios.get<{
			quizzes: Array<{ uid: string; name: string; visible_from: string; marks?: number }>
			quizSubmissions: Array<{
				submissionList: Array<{
					marks: number
					uid: string
					submitted: boolean
					givenMarks?: number | null
				}>
				submitted: number
				graded: number
				uid: string
			}>
			assignments: Array<{
				uid: string
				name: string
				visible_from: string
				_id: string
				marks?: number
				grade_type?: 'marks' | 'grades'
			}>
			assignmentSubmissions: Array<{
				submissionList: Array<{
					marks: number
					uid: string
					submitted: boolean
					givenMarks?: number | null
					grades?: string
				}>
				submitted: number
				graded: number
				uid: string
			}>
			users: Array<{
				current_module: number
				user: {
					first_name: string
					last_name: string
					uid: string
					username: string
					roll_num: string
				}
			}>
			// modules: Array<{
			// 	course_id: string
			// 	import_from_course_id: string
			// 	course_duration: number
			// }>
		}>(getUrl(`reporting/course/?course_id=${body.course_id}&asTeacher=${body.asTeacher}`), {
			...getDefaultOptions(),
		})
	)
}

export const getEverythingForAdmin = async ({
	available_from,
	available_till,
	Institute,
	year,
	status,
	Program,
	limit,
	offset,
}: {
	available_from: number
	available_till: number
	Institute: string
	year: string
	status: string
	Program: string
	limit: number
	offset: number
}) => {
	return await handleApi<any>(
		axios.post(
			getUrl('course/activities_for_admin'),
			{ available_from, available_till, Institute, year, status, Program, limit, offset },
			getDefaultOptions()
		)
	)
}

export const getEverythingForAdminForCourse = async ({
	available_from,
	available_till,
	Institute,
	year,
	status,
	Program,
}: {
	available_from: number
	available_till: number
	Institute: string
	year: string
	status: string
	Program: string
}) => {
	return await handleApi<any>(
		axios.post(getUrl('course/_activities_for_admin_'), { available_from, available_till, Institute, year, status, Program }, getDefaultOptions())
	)
}

export const importmodule = async ({
	course_id,
	import_from_course_id,
	course_duration,
}: {
	course_id: string
	import_from_course_id: string
	course_duration: string
}) => {
	return await handleApi<any>(
		axios.post(getUrl('asset/import/modules_from_course'), { course_id, import_from_course_id, course_duration }, getDefaultOptions())
	)
}

// export const importactivities = async ({
// 	course_id,
// 	import_from_course,
// 	module_duration,
// 	module_from,
// 	module_into,
// }: {
// 	course_id: string
// 	import_from_course: string
// 	module_duration: string
// 	module_from: string
// 	module_into: string
// }) => {
// 	return await handleApi<any>(
// 		axios.post(
// 			getUrl('asset/import/activities_into_module'),
// 			{ course_id, import_from_course, module_duration, module_from, module_into },
// 			getDefaultOptions()
// 		)
// 	)
// }

export const adminDashboardDetails = async () => {
	return await handleApi<any>(axios.get(getUrl('user/admin/dashboard'), getDefaultOptions()))
}
